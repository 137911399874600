import { Container, Box, Typography, useTheme } from '@mui/material';
import { Link } from 'components';
import { cyan } from '@mui/material/colors';

export function Contact() {
  const theme = useTheme();

  return (
    <Container
      sx={{
        marginTop: theme.spacing(8),
      }}>
      <Box mb={4}>
        <Typography
          color={cyan[700]}
          variant="h4"
          align="left"
          sx={{
            marginBottom: theme.spacing(4),
          }}>
          Contact Us
        </Typography>

        <Typography variant="body1" paragraph>
          We welcome any questions, comments, or feedback about the Partners in Parenting Kids - Autism program or research project.
        </Typography>

        <Typography variant="body1" paragraph>
          Email: <Link href="mailto:pipkids-autism@monash.edu">pipkids-autism@monash.edu</Link>
        </Typography>

        <Typography variant="body1" paragraph>
          Phone: <Link href="tel:(03) 9905 9448">(03) 9905 9448</Link>
        </Typography>
      </Box>
    </Container>
  );
}
